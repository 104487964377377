<template>
  <div class="individual-medal">
    <!--勋章 -->
    <div class="right">
      <h3>我的奖章</h3>
      <div class="medal">
        <ul class="get-medal-introduce">
          <li>
            <!--图片-->
            <div class="picture">
              <img src="../../assets/images/medal-boss1.png">
              <p>已获得</p>
            </div>
            <!--获得勋章介绍-->
            <div class="main">
              <h4><span>大佬奖章I</span><el-button>前去练习</el-button></h4>
              <p>开始学习并坚持一段时间才有可能成为大佬，连续7天完成模拟练习即可获得一级大佬奖章。</p>
              <!--达成几天状态-->
              <ul>
                <li>
                  <em></em>
                  <span>第一天</span>
                </li>
                <li>
                  <em></em>
                  <span>第二天</span>
                </li>
                <li>
                  <em></em>
                  <span>第三天</span>
                </li>
                <li>
                  <em></em>
                  <span>第五天</span>
                </li>
                <li>
                  <em></em>
                  <span>第五天</span>
                </li>
                <li>
                  <em></em>
                  <span>第六天</span>
                </li>
                <li>
                  <em></em>
                  <span>达成</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <!--图片-->
            <div class="picture">
              <img src="../../assets/images/medal-boss2.png">
            </div>
            <!--文字-->
            <div class="main">
              <h4><span>大佬奖章II</span><el-button>前去练习</el-button></h4>
              <p>仅坚持一段时间是无法获取全面的知识点的，进一步巩固知新是成为大佬的不可或缺的阶段，连续10天完成模拟练习即可获得二级大佬奖章。</p>
              <!--达成状态-->
              <ul>
                <li>
                  <em></em>
                  <span>第一天</span>
                </li>
                <li>
                  <em></em>
                  <span>第二天</span>
                </li>
                <li>
                  <em></em>
                  <span>第三天</span>
                </li>
                <li>
                  <em></em>
                  <span>第五天</span>
                </li>
                <li>
                  <em></em>
                  <span>第五天</span>
                </li>
                <li>
                  <em></em>
                  <span>第六天</span>
                </li>
                <li>
                  <em></em>
                  <span>达成</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <!--图片-->
            <div class="picture">
              <img src="../../assets/images/medal-boss3.png">
            </div>
            <!--文字-->
            <div class="main">
              <h4><span>大佬奖章III</span><el-button>前去练习</el-button></h4>
              <p>学了是会忘的，连续23天可以永远铭记知识点。不断的学习才可能成为大佬，连续23天完成模拟练习即可获得。</p>
              <!--达成状态-->
              <ul>
                <li>
                  <em></em>
                  <span>第一天</span>
                </li>
                <li>
                  <em></em>
                  <span>第二天</span>
                </li>
                <li>
                  <em></em>
                  <span>第三天</span>
                </li>
                <li>
                  <em></em>
                  <span>第五天</span>
                </li>
                <li>
                  <em></em>
                  <span>第五天</span>
                </li>
                <li>
                  <em></em>
                  <span>第六天</span>
                </li>
                <li>
                  <em></em>
                  <span>达成</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <!--图片-->
            <div class="picture">
              <img src="../../assets/images/medal-wish.png">
            </div>
            <!--主力别人-->
            <div class="main">
              <h4><span>助力达人</span><el-button>去愿望墙助力</el-button></h4>
              <p>帮助别人，也是帮助自己。助力他人愿望500+次即可获得。</p>
              <p>当前共助力<span>324</span>次，今日还可助力5次，今日还可助力50次哦，快去助力吧。</p>
            </div>
          </li>
          <!--分页-->
          <li><pagination/></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  @import "../../assets/style/variables";
  .individual-medal{
    .right{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: $background-color;
      padding: 0 20px;
      box-shadow: $background-shadow;
      border-radius: 20px;
      min-height: 925px;
      //标题
      h3{
        flex-shrink: 0;
        border-bottom: 1px solid #d8d8d8;
        margin: 0;
        padding: 15px 10px 5px 10px;
        font-weight: normal;
        font-size: 16px;
        color: $font-color-third;
      }
      .medal{
        flex-grow: 1;
        display: flex;
        .get-medal-introduce{
          width: 100%;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          & > li{
            display: flex;
            margin-top: 30px;
            //图片
            .picture{
              flex-shrink: 0;
              margin-right: 20px;
              img{
                width: 80px;
                height: 55px;
              }
              p{
                margin: 0 0 0 20px;
                font-size: 13px;
              }
            }
            //分页
            &:last-of-type{
              margin-top: 300px;
              .el-pagination{
                width: 100%;
              }
            }
            //第4个li
            &:nth-of-type(4){
              //爱心图片
              .picture{
                img{
                  width: 65px;
                  margin: 0 9px 0 10px;
                }
              }
              .main{
                p{
                 margin-bottom: 10px;
                  span{
                    text-align: center;
                    width: 30px;
                    display: inline-block;
                    background-color: #5B5B5B;
                    color: $primary-color;
                    margin: 0 2px;
                    border-radius: 20px;
                  }
                }
              }
            }
            //介绍怎么获取勋章
            .main{
              flex-grow: 1;
              h4{
                display: flex;
                margin: 0;
                font-size: 16px;
                span{
                  flex-grow: 1;
                }
                .el-button{
                  flex-shrink: 0;
                  height: 25px;
                  width: 110px;
                  padding: 0;
                  border-radius: 30px;
                  border: 1px solid $primary-color;
                  color: $primary-color;
                  font-size: 12px;
                }
              }
              p{
                width: 500px;
                margin: 0;
                color: $font-color-third;
                font-size: 12px;
              }
              //看看完成几天了
              ul{
                display: flex;
                margin: 10px 0 0 0;
                padding: 0;
                li{
                  display: flex;
                  flex-direction: column;
                  margin-right: 20px;
                  &:nth-of-type(1),&:nth-of-type(2){
                    em{
                      background-color: $primary-color;
                    }
                    span{
                      color: $primary-color;
                    }
                  }
                  em{
                    margin-left: 7.5px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-bottom: 3px;
                    background-color: #E0E0E0;
                  }
                  &:last-of-type{
                    em{
                      background-color: #38C74C;
                      margin-left: 2px;
                    }
                  }
                  span{
                    font-size: 12px;
                    color: $font-color-third;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
