var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"individual-medal"},[_c('div',{staticClass:"right"},[_c('h3',[_vm._v("我的奖章")]),_c('div',{staticClass:"medal"},[_c('ul',{staticClass:"get-medal-introduce"},[_c('li',[_vm._m(0),_c('div',{staticClass:"main"},[_c('h4',[_c('span',[_vm._v("大佬奖章I")]),_c('el-button',[_vm._v("前去练习")])],1),_c('p',[_vm._v("开始学习并坚持一段时间才有可能成为大佬，连续7天完成模拟练习即可获得一级大佬奖章。")]),_vm._m(1)])]),_c('li',[_vm._m(2),_c('div',{staticClass:"main"},[_c('h4',[_c('span',[_vm._v("大佬奖章II")]),_c('el-button',[_vm._v("前去练习")])],1),_c('p',[_vm._v("仅坚持一段时间是无法获取全面的知识点的，进一步巩固知新是成为大佬的不可或缺的阶段，连续10天完成模拟练习即可获得二级大佬奖章。")]),_vm._m(3)])]),_c('li',[_vm._m(4),_c('div',{staticClass:"main"},[_c('h4',[_c('span',[_vm._v("大佬奖章III")]),_c('el-button',[_vm._v("前去练习")])],1),_c('p',[_vm._v("学了是会忘的，连续23天可以永远铭记知识点。不断的学习才可能成为大佬，连续23天完成模拟练习即可获得。")]),_vm._m(5)])]),_c('li',[_vm._m(6),_c('div',{staticClass:"main"},[_c('h4',[_c('span',[_vm._v("助力达人")]),_c('el-button',[_vm._v("去愿望墙助力")])],1),_c('p',[_vm._v("帮助别人，也是帮助自己。助力他人愿望500+次即可获得。")]),_vm._m(7)])]),_c('li',[_c('pagination')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require("../../assets/images/medal-boss1.png")}}),_c('p',[_vm._v("已获得")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('em'),_c('span',[_vm._v("第一天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第二天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第三天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第五天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第五天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第六天")])]),_c('li',[_c('em'),_c('span',[_vm._v("达成")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require("../../assets/images/medal-boss2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('em'),_c('span',[_vm._v("第一天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第二天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第三天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第五天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第五天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第六天")])]),_c('li',[_c('em'),_c('span',[_vm._v("达成")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require("../../assets/images/medal-boss3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('em'),_c('span',[_vm._v("第一天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第二天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第三天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第五天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第五天")])]),_c('li',[_c('em'),_c('span',[_vm._v("第六天")])]),_c('li',[_c('em'),_c('span',[_vm._v("达成")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":require("../../assets/images/medal-wish.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("当前共助力"),_c('span',[_vm._v("324")]),_vm._v("次，今日还可助力5次，今日还可助力50次哦，快去助力吧。")])
}]

export { render, staticRenderFns }